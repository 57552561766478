import React from "react"
import Layout from "../components/layout"
import Nav from 'react-bootstrap/Nav'
import Carousel from 'react-bootstrap/Carousel'
import * as cn from "classnames"
import {Link} from "gatsby";
import {HelmetDatoCms} from "gatsby-source-datocms";

export default class Services extends React.Component {
    render() {
        const p = this.props, { } = p

        const product = p.data.product
        let carouselType
        if (product.gallery) {
            carouselType = product.gallery.length
        }
        else {
            carouselType = null
        }

        return (
            <Layout className="solid">
                <HelmetDatoCms seo={product.seoMetaTags} />
                <div className="breadcrumb">
                    <span>Produkty</span>
                    <a href={`/category/${product.category.slug}`} title={product.category.title}>{product.category.title}</a>
                    {product.subCategory && <a href={`/category/${product.category.slug}/${product.subCategory.slug}`} title={product.subCategory.title}>{product.subCategory.title}</a>}
                </div>
                <div className="container">
                    <div className="preview">
                        {carouselType && carouselType > 0 &&
                            <Carousel className={cn(carouselType === 1 && "single")}>
                                {product.gallery.map(({ fluid: image }) => (
                                    <Carousel.Item>
                                        {image && <img src={image.src} alt="" />}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        }
                        <div className="preview__content">
                            <div className="preview__left">
                                <h3>{product.title}</h3>
                                <p dangerouslySetInnerHTML={{
                                    __html: product.description,
                                }}
                                />
                            </div>
                            {product.advantages &&
                                <div className="preview__right">
                                    <h3>Zalety</h3>
                                    <div dangerouslySetInnerHTML={{
                                        __html: product.advantages,
                                    }}
                                    />
                                </div>
                            }
                        </div>
                        {product.powiazane && product.powiazane.length > 0 &&
                            <div>
                                <h3>Powiązane produkty:</h3>
                                <div className="more-products">
                                    {product.powiazane.map((item) => (
                                        <Link to={`/product/${item.slug}`} className="item">
                                            <div className="item__avatar">
                                                {item.gallery.length == 0 &&
                                                    <span className="icon-no-photo"></span>
                                                }
                                                {item.gallery.length > 0 && item.gallery.slice(0, 1).map(({ fluid: image }, idx) => (
                                                    <div key={idx}>
                                                        {image &&
                                                        <img src={image.src} alt="" />
                                                        }
                                                        {!image &&
                                                        <span className="icon-no-photo"></span>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="item__description">
                                                <h5>{item.title}</h5>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query CategorySerwiQuery($slug: String!) {
    product: datoCmsSerwi(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      description
      category {
        title
        slug
      }
      subCategory {
        title
        slug
      }
      gallery {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      powiazane {
        title
        slug
        gallery {
        fluid(imgixParams: { auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      }
    }
  }
`